
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { ITemplatesCategory } from '@/Interface';
import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers).extend({
	name: 'TemplateFilters',
	props: {
		sortOnPopulate: {
			type: Boolean,
			default: false,
		},
		categories: {
			type: Array,
		},
		expandLimit: {
			type: Number,
			default: 12,
		},
		loading: {
			type: Boolean,
		},
		selected: {
			type: Array,
		},
	},
	watch: {
		categories: {
			handler(categories: ITemplatesCategory[]) {
				if (!this.sortOnPopulate) {
					this.sortedCategories = categories;
				} else {
					const selected = this.selected || [];
					const selectedCategories = categories.filter(({ id }) => selected.includes(id));
					const notSelectedCategories = categories.filter(({ id }) => !selected.includes(id));
					this.sortedCategories = selectedCategories.concat(notSelectedCategories);
				}
			},
			immediate: true,
		},
	},
	data() {
		return {
			collapsed: true,
			sortedCategories: [] as ITemplatesCategory[],
		};
	},
	computed: {
		allSelected(): boolean {
			return this.selected.length === 0;
		},
	},
	methods: {
		collapseAction() {
			this.collapsed = false;
		},
		handleCheckboxChanged(value: boolean, selectedCategory: ITemplatesCategory) {
			this.$emit(value ? 'select' : 'clear', selectedCategory.id);
		},
		isSelected(categoryId: string) {
			return this.selected.includes(categoryId);
		},
		resetCategories() {
			this.$emit('clearAll');
		},
	},
});
