
import Vue from 'vue';

import { ITemplatesNode } from '@/Interface';
import { INodeTypeDescription } from 'n8n-workflow';

interface NodeIconData {
	type: string;
	path?: string;
	fileExtension?: string;
	fileBuffer?: string;
}

export default Vue.extend({
	name: 'HoverableNodeIcon',
	props: {
		circle: {
			type: Boolean,
			default: false,
		},
		clickButton: {
			type: Function,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		nodeType: {
			type: Object,
		},
		size: {
			type: Number,
		},
	},
	computed: {
		fontStyleData(): object {
			return {
				'max-width': this.size + 'px',
			};
		},
		iconStyleData(): object {
			const nodeType = this.nodeType as ITemplatesNode | null;
			const color = nodeType ? nodeType.defaults && nodeType!.defaults.color : '';
			if (!this.size) {
				return { color };
			}

			return {
				color,
				width: this.size + 'px',
				height: this.size + 'px',
				'font-size': this.size + 'px',
				'line-height': this.size + 'px',
				'border-radius': this.circle ? '50%' : '2px',
				...(this.disabled && {
					color: '#ccc',
					'-webkit-filter': 'contrast(40%) brightness(1.5) grayscale(100%)',
					filter: 'contrast(40%) brightness(1.5) grayscale(100%)',
				}),
			};
		},
		imageStyleData(): object {
			return {
				width: '100%',
				'max-width': '100%',
				'max-height': '100%',
			};
		},
		nodeIconData(): null | NodeIconData {
			const nodeType = this.nodeType as INodeTypeDescription | ITemplatesNode | null;
			if (nodeType === null) {
				return null;
			}

			if ((nodeType as ITemplatesNode).iconData) {
				return (nodeType as ITemplatesNode).iconData;
			}

			const restUrl = this.$store.getters.getRestUrl;

			if (nodeType.icon) {
				let type, path;
				[type, path] = nodeType.icon.split(':');
				const returnData: NodeIconData = {
					type,
					path,
				};

				if (type === 'file') {
					returnData.path = restUrl + '/node-icon/' + nodeType.name;
					returnData.fileExtension = path.split('.').slice(-1).join();
				}

				return returnData;
			}
			return null;
		},
	},
	data() {
		return {
			showTooltip: false,
		};
	},
});
