
import { genericHelpers } from '@/components/mixins/genericHelpers';
import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers).extend({
	name: 'Card',
	props: {
		loading: {
			type: Boolean,
		},
		title: {
			type: String,
		},
	},
});
