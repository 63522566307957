
import TemplateDetails from '@/components/TemplateDetails.vue';
import TemplateList from '@/components/TemplateList.vue';
import TemplatesView from './TemplatesView.vue';

import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import {
	ITemplatesCollection,
	ITemplatesCollectionFull,
	ITemplatesWorkflow,
	ITemplatesWorkflowFull,
} from '@/Interface';

import mixins from 'vue-typed-mixins';
import { setPageTitle } from '@/components/helpers';

export default mixins(workflowHelpers).extend({
	name: 'TemplatesCollectionView',
	components: {
		TemplateDetails,
		TemplateList,
		TemplatesView,
	},
	computed: {
		collection(): null | ITemplatesCollection | ITemplatesCollectionFull {
			return this.$store.getters['templates/getCollectionById'](this.collectionId);
		},
		collectionId(): string {
			return this.$route.params.id;
		},
		collectionWorkflows(): Array<ITemplatesWorkflow | ITemplatesWorkflowFull> | null {
			if (!this.collection) {
				return null;
			}
			return this.collection.workflows.map(({ id }) => {
				return this.$store.getters['templates/getTemplateById'](id) as ITemplatesWorkflow;
			});
		},
	},
	data() {
		return {
			loading: true,
			notFoundError: false,
		};
	},
	methods: {
		scrollToTop() {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}, 50);
		},
		onOpenTemplate({event, id}: {event: MouseEvent, id: string}) {
			this.navigateTo(event, 'TemplatesWorkflowView', id);
		},
		onUseWorkflow({event, id}: {event: MouseEvent, id: string}) {
			this.$telemetry.track('User inserted workflow template', {
				template_id: id,
				wf_template_repo_session_id: this.$store.getters['templates/currentSessionId'],
				source: 'collection',
			});

			this.navigateTo(event, 'WorkflowTemplate', id);
		},
		navigateTo(e: MouseEvent, page: string, id: string) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: page, params: { id } });
				window.open(route.href, '_blank');
				return;
			} else {
				this.$router.push({ name: page, params: { id } });
			}
		},
	},
	watch: {
		collection(collection: ITemplatesCollection) {
			if (collection) {
				setPageTitle(`n8n - Template collection: ${collection.name}`);
			}
			else {
				setPageTitle(`n8n - Templates`);
			}
		},
	},
	async mounted() {
		this.scrollToTop();

		if (this.collection && (this.collection as ITemplatesCollectionFull).full) {
			this.loading = false;
			return;
		}

		try {
			await this.$store.dispatch('templates/getCollectionById', this.collectionId);
		} catch (e) {
			this.notFoundError = true;
		}
		this.loading = false;
	},
});
