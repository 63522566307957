
import Card from '@/components/Card.vue';
import CollectionCard from '@/components/CollectionCard.vue';
import VueAgile from 'vue-agile';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers).extend({
	name: 'CollectionsCarousel',
	props: {
		collections: {
			type: Array,
		},
		loading: {
			type: Boolean,
		},
	},
	watch: {
		collections() {
			setTimeout(() => {
				this.updateCarouselScroll();
			}, 0);
		},
		loading() {
			setTimeout(() => {
				this.updateCarouselScroll();
			}, 0);
		},
	},
	components: {
		Card,
		CollectionCard,
		VueAgile,
	},
	data() {
		return {
			carouselScrollPosition: 0,
			cardWidth: 240,
			scrollEnd: false,
			listElement: null as null | Element,
		};
	},
	methods: {
		updateCarouselScroll() {
			if (this.listElement) {
				this.carouselScrollPosition = Number(this.listElement.scrollLeft.toFixed());

				const width = this.listElement.clientWidth;
				const scrollWidth = this.listElement.scrollWidth;
				const scrollLeft = this.carouselScrollPosition;
				this.scrollEnd = scrollWidth - width <= scrollLeft + 7;
			}
		},
		onCardClick(event: MouseEvent, id: string) {
			this.$emit('openCollection', {event, id});
		},
		scrollLeft() {
			if (this.listElement) {
				this.listElement.scrollBy({ left: -(this.cardWidth * 2), top: 0, behavior: 'smooth' });
			}
		},
		scrollRight() {
			if (this.listElement) {
				this.listElement.scrollBy({ left: this.cardWidth * 2, top: 0, behavior: 'smooth' });
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			const slider = this.$refs.slider;
			if (!slider) {
				return;
			}
			// @ts-ignore
			this.listElement = slider.$el.querySelector('.agile__list');
			if (this.listElement) {
				this.listElement.addEventListener('scroll', this.updateCarouselScroll);
			}
		});
	},
	beforeDestroy() {
		if (this.$refs.slider) {
			// @ts-ignore
			this.$refs.slider.destroy();
		}
		window.removeEventListener('scroll', this.updateCarouselScroll);
	},
});
